@import 'scss/bootstrap-variables';

.operator-ticket-form {
  .patient-contact-select {
    margin-bottom: 20px;
    padding: 20px;
    max-height: 820px;

    .title {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .phone-area {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      .phone-input {
        flex: 1;
        .phone-input-number-container {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          input {
            width: 110px;
          }
        }
      }

      .styled-input {
        width: 166px;
        .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .field {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .contact-selection {
      margin-bottom: 20px;
      max-height: 415px;
      overflow-y: scroll;
      padding-top: 4px;

      &.small {
        max-height: 370px;
      }
    }

    .phone-replace {
      display: none;
    }

    .hippa-auth-consent {
      font-weight: bold;
      transition: box-shadow 125ms;

      .styled-checkbox + label {
        padding-left: 32px;
        &:before {
          width: 20px;
          height: 20px;
          top: 16px;
        }
        &:after {
          width: 20px;
          height: 20px;
          top: 17px;
          left: 3px;
        }
      }
    }
  }

  .tickets-info-section {
    margin-top: 20px;

    .ticket-types-select {
      margin-bottom: 20px;
    }
  }
  .submit-section {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;
  }
}
